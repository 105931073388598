
import RcDomainObject from "@/domain/common/RcDomainObject.js";

export default class InvoiceItem extends RcDomainObject {
  
  static MODEL_NAME = "InvoiceItem";
  
  constructor(domain, data) {
    super(domain, data, InvoiceItem.MODEL_NAME);
  }
  
  static Fields = {
  }
  
  fields() {
    return InvoiceItem.FIELDS;
  }
  
}