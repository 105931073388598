<template>

  <div class="row">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle">
      <div 
        class="rc-text-light rc-left-padding rc-right-padding"
        :class="classConfig">
          &nbsp;{{ C.get(label) }}
      </div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import Catalog from '@/domain/session/Catalog.js';

export default {
  name: 'title-row',
  props: {
    label:    { type: String, default : "TITLE_ROW" },
    fontSize: { type: String, default : "rc-font-regular" },
    left:     { type: Number, default : 0 },
    right:    { type: Number, default : 0 },
    type:  { type: String, default: "" },
  },
  data() {
    return {
      C: new Catalog(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
    classConfig: function() {
      var colour = "rc-title-background-left";
      if (this.type === "warning") {
        colour = "rc-title-warning-left";
      }
      if (this.type === "error") {
        colour = "rc-title-error-left";
      }
      return this.fontSize + " " + colour
    }
  }
}
</script>