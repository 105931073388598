
import RcDomainMap from "../../common/RcDomainMap.js";
import InvoiceItem from './InvoiceItem.js';

export default class InvoiceItemMap extends RcDomainMap {
  static MODEL_NAME = InvoiceItem.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, InvoiceItemMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new InvoiceItemMap(this.domain, data);
  }
}