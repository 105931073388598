<template>

  <div class="row" cmpt="LabelRow">
    <div v-if="left" :class="leftPadding" />
    <div :class="middle">
      {{ catelog.get(label) }} 
    </div>
    <div v-if="right" :class="rightPadding" />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import Catelog from '@/domain/session/CanadianEnglish.js';


export default {
  name: 'label-row',
  props: {
    styling: { type: String, default : "rc-text-value rc-font-regular" },
    label: { type: String, default: "" },
    left: { type: Number, default: 0 },
    right: { type: Number, default: 0 },
    centre: { type: Boolean, default: false },
  },
  data() {
    return {
      catelogKeys: Catelog.KEYS,
      catelog: new Catelog(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      var centreCss = "";
      if (this.centre) {
        centreCss = " rc-text-centre";
      }
      return "rc-box-col-" + value + centreCss + " " + this.styling;
    },
  }
}
</script>