<template>

<div class="container"> <!-- main -->

  <SpacerRow />
  <ErrorRow
    :left="LEFT_SIDE" :right="RIGHT_SIDE"
    :error="error" />


  <Row v-if="showStatus">
    <Column :width="15">
      <Box>
        <TitleRow
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="Generating Payment Link"
          />
        <LabelRow
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="When finished you'll be sent to the payment page."/>
      
        <LabelRow
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="Working please wait..."/>
      
        <LabelRow
          v-if="link"
          :left="LEFT_SIDE" :right="RIGHT_SIDE"
          label="Done!"/>
      </Box>
    </Column>
  </Row>
  
  <Row v-if="alreadyPaid">
      <Column :width="LEFT_SIDE" />
      <Column :width="7">
        <Box>
          <ValueRow fontSize="medium"> 
            <img src="@/assets/2022/Heart.png" width="35px"/>
            Thanks for using ReadyChek. This invoice has already been paid!
          </ValueRow>
            
          <ValueRow fontSize="medium">
          </ValueRow>

          <Row v-if="auth_connected">
            <Column :width="12" />
            <Column :width="3">
              <router-link to="/signin" onclick="w3_close()" class="w3-bar-item w3-button rc-font-small rc-border-round">Sign In</router-link>
            </Column>
          </Row>
        </Box>
      </Column>
      <Column :width="RIGHT_SIDE" />
  </Row>
        
  <div v-if="link" class="row">
  
    <div class="rc-box-col-4" />
    <div class="rc-box-col-7 rc-text-xxlarge rc-text-label rc-text-centre">
       <a :href="link">Click the here or wait</a>
    </div>  
    <div class="rc-box-col-4" />
    
  </div>
  
  <SpacerRow />
  
  <div v-if="debug" class="row">
    <div class="rc-box-col-4" />
    <div class="rc-box-col-7">
      invoiceNumber={{ invoiceNumber }}
      isCreating= {{ isCreating }}
      link= {{ link }}
    </div>
    <div class="rc-box-col-4" />
  </div>
  
  <SpacerRow />
  
</div><!-- main -->

</template>

<script>

import { uuid } from 'vue-uuid'
import { mapGetters } from 'vuex'

import ErrorRow from '@/components/row/ErrorRow.vue';
import SpacerRow from '@/components/row/SpacerRow.vue';
import TitleRow from '@/components/row/TitleRow.vue';
import LabelRow from '@/components/row/LabelRow.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

import EventUtils from '@/utils/EventUtils.js';
//import ConstUtils from '@/utils/ConstUtils.js';

import Invoice from '@/domain/model/invoice/Invoice.js';
import InvoiceEvents from '@/domain/model/invoice/InvoiceEvents.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import RcObject from '@/domain/common/RcObject.js';
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';
//import RcDomain from '@/domain/RcDomain.js';

export default {
  name: "operator-billing-payment-link",
  components: {
    ErrorRow,
    SpacerRow,
    TitleRow,
    LabelRow,
    Row, Column, Box, ValueRow,
  },
  
  props: {
  },
  
  data() {
    return {
      invoiceId: "",
      isCreating: false,
      debug: false,
      error: null,
      link: null,
      LEFT_SIDE: 4,
      RIGHT_SIDE: 4,
      alreadyPaid: false,
      showStatus: true,
    };
  },
  
  computed: {
    ...mapGetters([
                   'InvoiceModel_PaymentLinkResponseEvent',
                   'auth_connected',
                   'auth_client',
                   'domain',
                ]),
    console: () => console,
  },
  
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.createPaymentLink();
      }
    },
    InvoiceModel_PaymentLinkResponseEvent() {
      if (this.InvoiceModel_PaymentLinkResponseEvent) {
        var response = new RcResponseEvent(this.domain, this.InvoiceModel_PaymentLinkResponseEvent);
        var payload = response.payload();
        if (response.error()) {
          var rcError = response.rcError();
          if (rcError.isInvoiceAlreadyPaid()) {
            this.alreadyPaid = true;
            this.showStatus = false;
          } else {
            this.error = InvoiceEvents.CreatePaymentLink.Errors.find(response.error());
            this.link = "mailto:support@readychek.com"
          }
        } else {
          this.link = new RcObject(payload.valuesFor("paymentLink")).data;
          if (!this.debug) {
//            window.open(this.link, "_self");
            window.location = this.link;
//            history.back();
          }
        }
        this.isCreating = false;
      }
    }
  },
  
  mounted: function () {
    this.invoiceNumber = this.$route.query.invoiceNumber;
    this.debug = this.$route.query['debug'];
    if (!this.debug) {
      this.debug = false;
    }
    this.send();
  },
  
  methods: {
    send: function() {
      if (this.auth_connected) {
        this.createPaymentLink();
      } else {
        this.$connect();  
      }
    },
    
    createPaymentLink: function() {
      this.isCreating = true;
      var invoice = new Invoice(this.domain);
      invoice.withNumber(this.invoiceNumber).done();
      const requestEvent = InvoiceEvents.CreatePaymentLink.Request(invoice);
      EventsDomain.initEvent(requestEvent, null, uuid.v1(), this.auth_client.deviceId, this.auth_client.version);
      EventUtils.Send.event(this.$socket, requestEvent);
    },
    
  }
}
</script>